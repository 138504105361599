<template>
  <v-container class="fill-height align-start" style="position: relative;">
    <v-overlay v-if="displaySkillTest">
      <skill-test-side-preview :user-skill-test="userSkillTest" @hide-skill-test="displaySkillTest=false"/>
    </v-overlay>

    <div v-if="userSkillTest" style="position: absolute; top: 2%; right: 0; z-index: 4;">
      <v-btn depressed color="accent" @click="displaySkillTest=true" small
             style="box-shadow:inset 0 0 0 2px white; border: #7E5A9B solid 1px;">Abrir Prova Seletiva</v-btn>
    </div>

    <v-row v-if="!$store.getters.isFreela">
      <v-col cols="12" style="font-size: x-large; font-weight: bold">
        <template v-if="userApprovalCode === 0">Obrigado por aplicar!<br>Em breve entraremos em contato por whatsapp.</template>
        <template v-else-if="userApprovalCode === 2">
          Infelizmente não te aprovamos desta vez, mas foi um prazer ter você conosco esta semana!<br>Fique de olho no WhatsApp para saber das próximas oportunidades.
        </template>
        <template v-else>Carregando...</template>
      </v-col>
    </v-row>
    <template v-else>
      <v-col cols="12">
        <v-card flat shaped class="secondary-border">
          <v-card-title>Conta Corrente</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-alert border="left" color="accent" text class="mb-0">Número de <b>provas transcritas: {{ sortedSubmittedExams.length }}</b></v-alert>
              </v-col>
              <v-col cols="6">
                <v-alert border="left" color="accent" text class="mb-0">Número de <b>provas aprovadas: {{ sortedSubmittedExams.filter(e => e.isPaid).length }}</b></v-alert>
              </v-col>
              <v-col cols="12">
                <v-alert icon="mdi-currency-usd" border="bottom" color="success" text class="mb-0">
                  <b>Saldo acumulado</b>: <b>R$ {{ 5*(sortedSubmittedExams.length - (userAccount.paidExams || []).length) }}</b> crédito + <b>R$ {{ 5*(userAccount.paidExams || []).length }}</b> resgatados = <b>R$ {{ 5*sortedSubmittedExams.length }} totais</b>
                </v-alert>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider/>
          <v-card-actions style="padding: 12px 16px;">
            <v-row>
              <v-col cols="12">
                <div style="width: 100%;" class="d-inline-flex justify-space-between">
                  <v-btn @click="displayAccount=!displayAccount" depressed color="accent" small
                         class="text-capitalize">{{ displayAccount ? 'Fechar' : 'Adicionar dados de pagamento' }}</v-btn>
                  <v-btn v-if="displayAccount" @click="sendPaymentInfo" class="text-capitalize" depressed color="success" small>
                    Modificar dados de pagamento</v-btn>
                </div>
                <template v-if="displayAccount">
                  <v-text-field v-model="userAccount.pixKey" hide-details solo-inverted flat
                                prefix="Chave PIX: " color="secondary" class="mt-4"></v-text-field>
                  <v-text-field v-model="userAccount.accountOwner" hide-details solo-inverted flat
                                prefix="Nome do titular: " color="secondary" class="mt-4"></v-text-field>
                </template>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-row>
        <v-col cols="12">
          <v-card color="primary" class="white--text">
            <v-card-title>Provas Submetidas por você</v-card-title>
            <v-card-text v-if="sortedSubmittedExams.length">
              <v-list color="primary" rounded class="lighten-1">
                <v-list-item v-for="(exam, eIdx) in sortedSubmittedExams" :key="eIdx">
                  <v-list-item-content style="position: relative;">
                    <v-list-item-title v-text="exam.examName"/>
                    {{ exam.authorName }} - {{ timestampToDate(exam.submissionTime) }}
                    <div style="display: inline-flex; position: absolute; right: 10px;" class="white--text">
                      <div v-if="(userAccount.paidExams || []).includes(exam.eid)" class="accent rounded-pill pa-3">Pago</div>
                      <div class="primary rounded-pill pa-3 ml-2">{{ exam.isPaid ? 'Submissão Aprovada' : 'Aprovação Pendente' }}</div>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import {db} from "@/main";
import SkillTestSidePreview from "@/components/SkillTestSidePreview";
export default {
  name: "Submissions",
  components: { SkillTestSidePreview },
  data() { return {
    displayAccount: false,
    userApprovalCode: null, // 0 - pending, 1 - approved, 2 - refused
    submittedExams: {},
    userAccount: {},
    displaySkillTest: false,
    userSkillTest: null,
  } },
  created() {
    db.collection('SkillTests').doc(this.$store.state.auth.user.uid).get().then((doc) => {
      if (doc.exists) {
        this.userApprovalCode = (doc.data()).approvalCode; this.userSkillTest = doc.data();
      }
    });
    if (this.$store.getters.isFreela) {
      db.collection('SubmissionReports').where('authorId', '==', this.$store.state.auth.user.uid).get()
        .then((snap) => { snap.forEach((exam) => { this.$set(this.submittedExams, exam.id, exam.data()); }); });
      db.collection('FreelaAccounts').doc(this.$store.state.auth.user.uid).get()
        .then((doc) => { if (doc.exists) { this.userAccount = doc.data(); this.$forceUpdate(); } });
    }
  },
  computed: {
    sortedSubmittedExams() {
      const unpaidArray = [];
      const paidArray = [];
      for (const examId of Object.keys(this.submittedExams)) {
        const exam = this.submittedExams[examId];
        exam.isPaid ? paidArray.push({ eid: examId, ...exam }) : unpaidArray.push({ eid: examId, ...exam });
      }
      unpaidArray.sort((a, b) => { return a.submissionTime - b.submissionTime });
      paidArray.sort((a, b) => { return a.submissionTime - b.submissionTime });
      return [...unpaidArray, ...paidArray];
    },
  },
  methods: {
    timestampToDate(d) {
      const tDate = new Date(d);
      return `${('0' + tDate.getHours()).substr(-2)}:${('0' + tDate.getMinutes()).substr(-2)} - ${('0' + tDate.getDate()).substr(-2)}/${('0' + (tDate.getMonth() + 1)).substr(-2)}`;
    },
    async sendPaymentInfo() {
      this.$store.commit('enableLoadingState');
      try {
        await db.collection('FreelaAccounts').doc(this.$store.state.auth.user.uid).set({
          pixKey: this.userAccount.pixKey, accountOwner: this.userAccount.accountOwner
        }, { merge: true });
        this.displayAccount = false;
      } catch (e) { alert(`Ocorreu um erro: ${JSON.stringify(e)}. Por favor, entre em contato conosco.`); }
      finally { this.$store.commit('disableLoadingState'); }
    },
    /*getQuestionsFromId(examName, enableTags) {
    this.examQuestions = [];
    const EID = Object.keys(this.getCompletedExams).find(key => this.getCompletedExams[key] === examName);
    this.$store.commit('enableLoadingState');
    db.collection('AdminQuestions').where('examID', '==', EID).get()
      .then(snapshot => {
        if (!snapshot.empty) {
          snapshot.forEach(doc => { this.examQuestions.push([doc.id, doc.data()]) });
          this.enableTagInsertion = enableTags;
        } else { console.log('No questions found'); }
      })
      .catch(err => console.log(err))
      .finally(() => {
        this.$store.commit('disableLoadingState'); this.reRender(); // TODO: smooth scroll does not work in IE/Edge. scrollIntoView(false) works
        document.getElementById('question-preview-container').scrollIntoView({ behavior: 'smooth', block: 'start' }); });
    },*/
  }
}
</script>

<style lang="scss">
 .secondary-border {
   border-color: var(--v-secondary-base) !important; border-width: 3px; border-style: solid;
 }
</style>
