<template>
  <v-card :light="!$vuetify.theme.dark" color="secondary" style="width: 90vw; height: 90vh; overflow-y: scroll !important;">
    <v-card-title>
      Prova Seletiva - {{ userSkillTest.name }}
      <v-spacer/>
      <v-card style="display: flex; position: fixed; top: 6%; right: 8%; z-index: 999; padding: 0.5rem;" elevation="20">
        <v-btn @click="toggleInspectionMode" depressed small color="accent" class="white--text">INSPECIONAR</v-btn>
        <v-btn @click="$emit('hide-skill-test')" depressed small color="error" class="white--text ml-2">FECHAR</v-btn>
        <br>
        <v-btn @click="sideBySide=!sideBySide" depressed small color="primary" class="white--text ml-2">Lado a Lado</v-btn>
      </v-card>
    </v-card-title>
    <v-card-text>
      <v-row dense v-for="(questionData, qIdx) in Object.values(userSkillTest.questions).sort((a, b) => { return a.questionNumber - b.questionNumber })" :key="`user-${qIdx}`">
        <v-col :cols="sideBySide?6:12">
          <v-card color="card">
            <v-card-title class="accent white--text mb-1" style="opacity: 1.0; font-size: 0.8rem; word-break: break-all !important; padding: 8px 16px !important;">
              <v-row dense>
                <v-col cols="12" class="d-flex" style="cursor: pointer !important;">
                  <div class="d-inline-flex align-center justify-space-between flex-grow-1">
            <span>
              {{ `Q${questionData.questionNumber} - ${questionData.meta.subject}` }}
            </span>
                  </div>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-container :class="{'desktop-container-padding': $vuetify.breakpoint.lgAndUp}">
                <v-row v-if="parsedEnunciados(questionData)[0]" style="font-size: 1rem;" class="d-block enunciado tex2jax_process text--primary"
                       v-html="parsedEnunciados(questionData)[0]"/>
                <v-row v-for="(itemEnunciado, itemEnunciadoIndex) in parsedEnunciados(questionData)[1]" :key="itemEnunciadoIndex">
                  <v-container v-if="itemEnunciado" class="d-block enunciado tex2jax_process text--primary" style="font-size: 1rem" v-html="itemEnunciado"/>
                  <v-row v-if="parsedGabaritos(questionData)[1][itemEnunciadoIndex]" dense style="margin: -12px 20px 0 20px; overflow-x: auto; border: darkgray solid 0; border-top-width: thin; border-bottom-width: thin;">
                    <v-col cols="12" xl="9" lg="9">
                      <v-container class="d-block enunciado tex2jax_process text--primary"
                                   style="text-align: center; overflow-x: auto;" v-html="'Gabarito: ' + parsedGabaritos(questionData)[1][itemEnunciadoIndex]"/>
                    </v-col>
                  </v-row>
                </v-row>
                <v-row v-if="questionData.dados" style="overflow-x: auto;" class="ma-2">
                  <v-container style="border: 1px solid #7E5A9B; width: fit-content; height: fit-content"
                               class="enunciado tex2jax_process text--primary" :class="{'desktop-dados-alignment': $vuetify.breakpoint.lgAndUp}"
                               v-html="questionData.dados.match(/(?:\$+[^$\\]*(?:\\[\s\S][^$\\]*)*\$+|[^,])+/g).map(s => s.trim()).join('<br/>')"/>
                </v-row>
                <br v-if="parsedAlternativas(questionData).length>0 || questionData.dados">
                <v-row v-if="parsedAlternativas(questionData).length>0" style="margin-bottom: -2vh">
                  <v-col cols="12" xl="10" lg="10" :class="{'desktop-alternatives-padding': $vuetify.breakpoint.lgAndUp}">
                    <v-radio-group :disabled="!parsedGabaritos(questionData)[0]"
                                   style="margin-top: -2.4vh; padding-top: 0" class="alternative-group">
                      <v-radio :on-icon="alphabetIcons[altIndex]" :off-icon="alphabetIcons[altIndex]" style="padding-bottom: 1vh"
                               class="d-block enunciado tex2jax_process text--primary" v-for="(alternative, altIndex) in parsedAlternativas(questionData)" :ripple="false"
                               :key="altIndex" :label="alternative.value"/>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12" xl="2" lg="2" v-if="!parsedGabaritos(questionData)[0]" style="position: relative; margin-top: -3vh; margin-bottom: 2vh;" class="d-flex align-end justify-center justify-lg-end">
                    <v-btn disabled right>Sem Gabarito</v-btn>
                  </v-col>
                </v-row>
                <v-row v-if="parsedGabaritos(questionData)[0]" dense style="margin: 0; min-height: 55px; overflow-x: auto; border-top: darkgray solid thin;">
                  <v-col cols="12" xl="9" lg="9">
                    <v-container class="d-block enunciado tex2jax_process text--primary"
                                 style="text-align: center; overflow-x: auto;" v-html="'Gabarito: ' + parsedGabaritos(questionData)[0]"/>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col :cols="sideBySide?6:12">
          <v-card color="card">
            <v-card-title class="primary white--text mb-1" style="opacity: 1.0; font-size: 0.8rem; word-break: break-all !important; padding: 8px 16px !important;">
              <v-row dense>
                <v-col cols="12" class="d-flex" style="cursor: pointer !important;">
                  <div class="d-inline-flex align-center justify-space-between flex-grow-1">
            <span>
              {{ `Q${qIdx+1} - Oficial` }}
            </span>
                  </div>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-container :class="{'desktop-container-padding': $vuetify.breakpoint.lgAndUp}">
                <v-row v-if="parsedEnunciados(officialSkillTest[qIdx])[0]" style="font-size: 1rem;" class="d-block enunciado tex2jax_process text--primary"
                       v-html="parsedEnunciados(officialSkillTest[qIdx])[0]"/>
                <v-row v-for="(itemEnunciado, itemEnunciadoIndex) in parsedEnunciados(officialSkillTest[qIdx])[1]" :key="itemEnunciadoIndex">
                  <v-container v-if="itemEnunciado" class="d-block enunciado tex2jax_process text--primary" style="font-size: 1rem" v-html="itemEnunciado"/>
                  <v-row v-if="parsedGabaritos(officialSkillTest[qIdx])[1][itemEnunciadoIndex]" dense style="margin: -12px 20px 0 20px; overflow-x: auto; border: darkgray solid 0; border-top-width: thin; border-bottom-width: thin;">
                    <v-col cols="12" xl="9" lg="9">
                      <v-container class="d-block enunciado tex2jax_process text--primary"
                                   style="text-align: center; overflow-x: auto;" v-html="'Gabarito: ' + parsedGabaritos(officialSkillTest[qIdx])[1][itemEnunciadoIndex]"/>
                    </v-col>
                  </v-row>
                </v-row>
                <v-row v-if="officialSkillTest[qIdx].dados" style="overflow-x: auto;" class="ma-2">
                  <v-container style="border: 1px solid #7E5A9B; width: fit-content; height: fit-content"
                               class="enunciado tex2jax_process text--primary" :class="{'desktop-dados-alignment': $vuetify.breakpoint.lgAndUp}"
                               v-html="officialSkillTest[qIdx].dados.match(/(?:\$+[^$\\]*(?:\\[\s\S][^$\\]*)*\$+|[^,])+/g).map(s => s.trim()).join('<br/>')"/>
                </v-row>
                <br v-if="parsedAlternativas(officialSkillTest[qIdx]).length>0 || officialSkillTest[qIdx].dados">
                <v-row v-if="parsedAlternativas(officialSkillTest[qIdx]).length>0" style="margin-bottom: -2vh">
                  <v-col cols="12" xl="10" lg="10" :class="{'desktop-alternatives-padding': $vuetify.breakpoint.lgAndUp}">
                    <v-radio-group :disabled="!parsedGabaritos(officialSkillTest[qIdx])[0]"
                                   style="margin-top: -2.4vh; padding-top: 0" class="alternative-group">
                      <v-radio :on-icon="alphabetIcons[altIndex]" :off-icon="alphabetIcons[altIndex]" style="padding-bottom: 1vh"
                               class="d-block enunciado tex2jax_process text--primary" v-for="(alternative, altIndex) in parsedAlternativas(officialSkillTest[qIdx])" :ripple="false"
                               :key="altIndex" :label="alternative.value"/>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12" xl="2" lg="2" v-if="!parsedGabaritos(officialSkillTest[qIdx])[0]" style="position: relative; margin-top: -3vh; margin-bottom: 2vh;" class="d-flex align-end justify-center justify-lg-end">
                    <v-btn disabled right>Sem Gabarito</v-btn>
                  </v-col>
                </v-row>
                <v-row v-if="parsedGabaritos(officialSkillTest[qIdx])[0]" dense style="margin: 0; min-height: 55px; overflow-x: auto; border-top: darkgray solid thin;">
                  <v-col cols="12" xl="9" lg="9">
                    <v-container class="d-block enunciado tex2jax_process text--primary"
                                 style="text-align: center; overflow-x: auto;" v-html="'Gabarito: ' + parsedGabaritos(officialSkillTest[qIdx])[0]"/>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "SkillTestSidePreview",
  props: ['userSkillTest'],
  data() { return {
    sideBySide: false,
    inspectionMode: false,
    alphabetIcons: ['mdi-alpha-a-circle', 'mdi-alpha-b-circle', 'mdi-alpha-c-circle', 'mdi-alpha-d-circle', 'mdi-alpha-e-circle', 'mdi-alpha-f-circle', 'mdi-alpha-g-circle'],
    officialSkillTest: [
      {
        images: '[]',
        comments: [],
        flagCE: true,
        dados: null,
        answers: [],
        questionNumber: 1,
        relatedText: null,
        tags: {},
        difficulty: null,
        enunciados: '["<p>Um dos desafios da agricultura moderna é gastar menos água nas lavouras sem comprometer a produtividade. Pesquisadores do Instituto de Química da Universidade de São Paulo, <em>campus</em> de São Carlos (IQSC-USP), desenvolveram um hidrogel biodegradável e superabsorvente para uso em lavouras cultivadas em solos áridos que sofrem com a seca, ou em plantações irrigadas, a fim de reduzir o consumo de água. Misturado ao solo, o produto tem capacidade de absorver grandes quantidades de água da irrigação — natural (chuva) ou artificial —, servindo como uma reserva hídrica em períodos de estiagem. Quando a água líquida é absorvida pelo hidrogel, o produto resultante também é um gel. O hidrogel é produzido a partir de polímeros naturais, como quitosana, derivada da quitina, um polissacarídeo encontrado no exoesqueleto de crustáceos, e goma gelana, que é obtida a partir da bactéria <em>Sphingomonas elodea</em>, após a fermentação da glicose, utilizando-se álcool isopropílico. A goma é, então, seca e moída.</p><p>Considere que a reação de polimerização que produz a quitina seja a seguinte.</p><p>$$\\\\ce{ \\\\underset{\\\\text{N-acetilglicosamina}}{x C8H15O6N} \\\\rightleftharpoons \\\\underset{\\\\text{quitina}}{(C8H13O5N)_y} + z H2O}$$</p><p>Acerca do assunto tratado no texto, e considerando que a constante de Avogadro seja igual a $\\\\pu{6,02 \\\\times 10^{23} mol-1}$, julgue os itens de <strong>21</strong> a <strong>28</strong> e assinale a opção correta no item <strong>29</strong>, que é do <strong>tipo C</strong>.</p><p><strong>21 </strong>$x=y=z$</p><p></p>",[]]',
        numCorrectResponders: 0,
        gabaritos: '[null,[]]',
        meta: { institution: 'PROVA SELETIVA', year: '2022', subject: null },
        examID: 'VOuFj4xjGIe6wZhiJalvKFKxsza2',
        alternatives: '[{"value":"Certo"},{"value":"Errado"}]',
        numResponders: 0,
        pendingImages: false
      },
      {
        images: '[]',
        comments: [],
        flagCE: true,
        dados: null,
        answers: [],
        questionNumber: 2,
        relatedText: null,
        tags: {},
        difficulty: null,
        enunciados: '["<p>Um dos desafios da agricultura moderna é gastar menos água nas lavouras sem comprometer a produtividade. Pesquisadores do Instituto de Química da Universidade de São Paulo, <em>campus</em> de São Carlos (IQSC-USP), desenvolveram um hidrogel biodegradável e superabsorvente para uso em lavouras cultivadas em solos áridos que sofrem com a seca, ou em plantações irrigadas, a fim de reduzir o consumo de água. Misturado ao solo, o produto tem capacidade de absorver grandes quantidades de água da irrigação — natural (chuva) ou artificial —, servindo como uma reserva hídrica em períodos de estiagem. Quando a água líquida é absorvida pelo hidrogel, o produto resultante também é um gel. O hidrogel é produzido a partir de polímeros naturais, como quitosana, derivada da quitina, um polissacarídeo encontrado no exoesqueleto de crustáceos, e goma gelana, que é obtida a partir da bactéria <em>Sphingomonas elodea</em>, após a fermentação da glicose, utilizando-se álcool isopropílico. A goma é, então, seca e moída.</p><p>Considere que a reação de polimerização que produz a quitina seja a seguinte.</p><p>$$\\\\ce{ \\\\underset{\\\\text{N-acetilglicosamina}}{x C8H15O6N} \\\\rightleftharpoons \\\\underset{\\\\text{quitina}}{(C8H13O5N)_y} + z H2O}$$</p><p>Acerca do assunto tratado no texto, e considerando que a constante de Avogadro seja igual a $\\\\pu{6,02 \\\\times 10^{23} mol-1}$, julgue os itens de <strong>21</strong> a <strong>28</strong> e assinale a opção correta no item <strong>29</strong>, que é do <strong>tipo C</strong>.</p><p><strong>22 </strong>A absorção da água pelo hidrogel pode ser corretamente classificada como fenômeno químico.</p><p></p>",[]]',
        numCorrectResponders: 0,
        gabaritos: '[null,[]]',
        meta: { institution: 'PROVA SELETIVA', year: '2022', subject: null },
        examID: 'VOuFj4xjGIe6wZhiJalvKFKxsza2',
        alternatives: '[{"value":"Certo"},{"value":"Errado"}]',
        numResponders: 0,
        pendingImages: false
      },
      {
        images: '[]',
        comments: [],
        flagCE: true,
        dados: null,
        answers: [],
        questionNumber: 3,
        relatedText: null,
        tags: {},
        difficulty: null,
        enunciados: '["<p>Um dos desafios da agricultura moderna é gastar menos água nas lavouras sem comprometer a produtividade. Pesquisadores do Instituto de Química da Universidade de São Paulo, <em>campus</em> de São Carlos (IQSC-USP), desenvolveram um hidrogel biodegradável e superabsorvente para uso em lavouras cultivadas em solos áridos que sofrem com a seca, ou em plantações irrigadas, a fim de reduzir o consumo de água. Misturado ao solo, o produto tem capacidade de absorver grandes quantidades de água da irrigação — natural (chuva) ou artificial —, servindo como uma reserva hídrica em períodos de estiagem. Quando a água líquida é absorvida pelo hidrogel, o produto resultante também é um gel. O hidrogel é produzido a partir de polímeros naturais, como quitosana, derivada da quitina, um polissacarídeo encontrado no exoesqueleto de crustáceos, e goma gelana, que é obtida a partir da bactéria <em>Sphingomonas elodea</em>, após a fermentação da glicose, utilizando-se álcool isopropílico. A goma é, então, seca e moída.</p><p>Considere que a reação de polimerização que produz a quitina seja a seguinte.</p><p>$$\\\\ce{ \\\\underset{\\\\text{N-acetilglicosamina}}{x C8H15O6N} \\\\rightleftharpoons \\\\underset{\\\\text{quitina}}{(C8H13O5N)_y} + z H2O}$$</p><p>Acerca do assunto tratado no texto, e considerando que a constante de Avogadro seja igual a $\\\\pu{6,02 \\\\times 10^{23} mol-1}$, julgue os itens de <strong>21</strong> a <strong>28</strong> e assinale a opção correta no item <strong>29</strong>, que é do <strong>tipo C</strong>.</p><p><strong>23 </strong>Quando a água de irrigação natural irriga o solo, ocorre a mudança do estado de agregação de uma substância química.</p>",[]]',
        numCorrectResponders: 0,
        gabaritos: '[null,[]]',
        meta: { institution: 'PROVA SELETIVA', year: '2022', subject: null },
        examID: 'VOuFj4xjGIe6wZhiJalvKFKxsza2',
        alternatives: '[{"value":"Certo"},{"value":"Errado"}]',
        numResponders: 0,
        pendingImages: false
      },
      {
        images: '[]',
        comments: [],
        flagCE: true,
        dados: null,
        answers: [],
        questionNumber: 4,
        relatedText: null,
        tags: {},
        difficulty: null,
        enunciados: '["<p>Um dos desafios da agricultura moderna é gastar menos água nas lavouras sem comprometer a produtividade. Pesquisadores do Instituto de Química da Universidade de São Paulo, <em>campus</em> de São Carlos (IQSC-USP), desenvolveram um hidrogel biodegradável e superabsorvente para uso em lavouras cultivadas em solos áridos que sofrem com a seca, ou em plantações irrigadas, a fim de reduzir o consumo de água. Misturado ao solo, o produto tem capacidade de absorver grandes quantidades de água da irrigação — natural (chuva) ou artificial —, servindo como uma reserva hídrica em períodos de estiagem. Quando a água líquida é absorvida pelo hidrogel, o produto resultante também é um gel. O hidrogel é produzido a partir de polímeros naturais, como quitosana, derivada da quitina, um polissacarídeo encontrado no exoesqueleto de crustáceos, e goma gelana, que é obtida a partir da bactéria <em>Sphingomonas elodea</em>, após a fermentação da glicose, utilizando-se álcool isopropílico. A goma é, então, seca e moída.</p><p>Considere que a reação de polimerização que produz a quitina seja a seguinte.</p><p>$$\\\\ce{ \\\\underset{\\\\text{N-acetilglicosamina}}{x C8H15O6N} \\\\rightleftharpoons \\\\underset{\\\\text{quitina}}{(C8H13O5N)_y} + z H2O}$$</p><p>Acerca do assunto tratado no texto, e considerando que a constante de Avogadro seja igual a $\\\\pu{6,02 \\\\times 10^{23} mol-1}$, julgue os itens de <strong>21</strong> a <strong>28</strong> e assinale a opção correta no item <strong>29</strong>, que é do <strong>tipo C</strong>.</p><p><strong>24 </strong>A reação apresentada leva a conclusão de que $x$ mols de N-acetilglicosamina produzem $y$ mols de quitina.</p>",[]]',
        numCorrectResponders: 0,
        gabaritos: '[null,[]]',
        meta: { institution: 'PROVA SELETIVA', year: '2022', subject: null },
        examID: 'VOuFj4xjGIe6wZhiJalvKFKxsza2',
        alternatives: '[{"value":"Certo"},{"value":"Errado"}]',
        numResponders: 0,
        pendingImages: false
      },
      {
        images: '[]',
        comments: [],
        flagCE: true,
        dados: null,
        answers: [],
        questionNumber: 5,
        relatedText: null,
        tags: {},
        difficulty: null,
        enunciados: '["<p>Um dos desafios da agricultura moderna é gastar menos água nas lavouras sem comprometer a produtividade. Pesquisadores do Instituto de Química da Universidade de São Paulo, <em>campus</em> de São Carlos (IQSC-USP), desenvolveram um hidrogel biodegradável e superabsorvente para uso em lavouras cultivadas em solos áridos que sofrem com a seca, ou em plantações irrigadas, a fim de reduzir o consumo de água. Misturado ao solo, o produto tem capacidade de absorver grandes quantidades de água da irrigação — natural (chuva) ou artificial —, servindo como uma reserva hídrica em períodos de estiagem. Quando a água líquida é absorvida pelo hidrogel, o produto resultante também é um gel. O hidrogel é produzido a partir de polímeros naturais, como quitosana, derivada da quitina, um polissacarídeo encontrado no exoesqueleto de crustáceos, e goma gelana, que é obtida a partir da bactéria <em>Sphingomonas elodea</em>, após a fermentação da glicose, utilizando-se álcool isopropílico. A goma é, então, seca e moída.</p><p>Considere que a reação de polimerização que produz a quitina seja a seguinte.</p><p>$$\\\\ce{ \\\\underset{\\\\text{N-acetilglicosamina}}{x C8H15O6N} \\\\rightleftharpoons \\\\underset{\\\\text{quitina}}{(C8H13O5N)_y} + z H2O}$$</p><p>Acerca do assunto tratado no texto, e considerando que a constante de Avogadro seja igual a $\\\\pu{6,02 \\\\times 10^{23} mol-1}$, julgue os itens de <strong>21</strong> a <strong>28</strong> e assinale a opção correta no item <strong>29</strong>, que é do <strong>tipo C</strong>.</p><p><strong>25 </strong>Para se produzir 1 $\\\\pu{mol}$ de quitina, são necessárias $6,02 \\\\times 10^{23} \\\\times x$ moléculas de N-acetilglicosamina.</p>",[]]',
        numCorrectResponders: 0,
        gabaritos: '[null,[]]',
        meta: { institution: 'PROVA SELETIVA', year: '2022', subject: null },
        examID: 'VOuFj4xjGIe6wZhiJalvKFKxsza2',
        alternatives: '[{"value":"Certo"},{"value":"Errado"}]',
        numResponders: 0,
        pendingImages: false
      },
      {
        images: '[]',
        comments: [],
        flagCE: true,
        dados: null,
        answers: [],
        questionNumber: 6,
        relatedText: null,
        tags: {},
        difficulty: null,
        enunciados: '["<p>A grande pirâmide de Giza, construída para o faraó Khufu, em $2694$ a.C., é uma façanha da arquitetura e engenharia que atrai, há séculos, a imaginação e curiosidade dos mais diversos povos. Ela possui $2.300.000$ blocos, cujos pesos variam entre $2,5$ e $80$ toneladas, e levou $20$ anos para ser construída. Do ponto de vista da matemática, a pirâmide de Khufu é uma obra fascinante, pelo fato de se aproximar de uma pirâmide áurea. A razão áurea desperta bastante curiosidade por ter sido associada a uma estética agradável e por ter relações com outros objetos matemáticos como a sequência de Fibonacci, definida por $f_{n+2} = f_n + f_{n+1}$, para todo $n \\\\geq 1$, com $f_1 = f_2 = 1$. Uma pirâmide reta de base quadrada será áurea se a razão entre apótema de uma face lateral e a metade da aresta da base for a razão áurea $\\\\varphi$, que corresponde à raiz positiva da equação $ r^2 - r - 1 = 0$. A figura seguinte ilustra uma pirâmide reta de base quadrada que possui as mesmas proporções da grande pirâmide de Giza.</p><p>[Imagem]</p><p>Considerando o texto e a imagem precedentes, julgue os itens a seguir.</p><p><strong>55 </strong>Infere-se das informações apresentadas no texto que, durante a construção da pirâmide, foram colocados, em média, mais de $300$ blocos a cada dia</p>",[]]',
        numCorrectResponders: 0,
        gabaritos: '[null,[]]',
        meta: { institution: 'PROVA SELETIVA', year: '2022', subject: null },
        examID: 'VOuFj4xjGIe6wZhiJalvKFKxsza2',
        alternatives: '[{"value":"Certo"},{"value":"Errado"}]',
        numResponders: 0,
        pendingImages: true
      },
      {
        images: '[]',
        comments: [],
        flagCE: true,
        dados: null,
        answers: [],
        questionNumber: 7,
        relatedText: null,
        tags: {},
        difficulty: null,
        enunciados: '["<p>A grande pirâmide de Giza, construída para o faraó Khufu, em $2694$ a.C., é uma façanha da arquitetura e engenharia que atrai, há séculos, a imaginação e curiosidade dos mais diversos povos. Ela possui $2.300.000$ blocos, cujos pesos variam entre $2,5$ e $80$ toneladas, e levou $20$ anos para ser construída. Do ponto de vista da matemática, a pirâmide de Khufu é uma obra fascinante, pelo fato de se aproximar de uma pirâmide áurea. A razão áurea desperta bastante curiosidade por ter sido associada a uma estética agradável e por ter relações com outros objetos matemáticos como a sequência de Fibonacci, definida por $f_{n+2} = f_n + f_{n+1}$, para todo $n \\\\geq 1$, com $f_1 = f_2 = 1$. Uma pirâmide reta de base quadrada será áurea se a razão entre apótema de uma face lateral e a metade da aresta da base for a razão áurea $\\\\varphi$, que corresponde à raiz positiva da equação $ r^2 - r - 1 = 0$. A figura seguinte ilustra uma pirâmide reta de base quadrada que possui as mesmas proporções da grande pirâmide de Giza.</p><p>[Imagem]</p><p>Considerando o texto e a imagem precedentes, julgue os itens a seguir.</p><p><strong>56 </strong>Se $\\\\psi$ é a raiz negativa da equação $ r^2 - r - 1 = 0$, então $\\\\psi = -\\\\frac{1}{\\\\varphi}$</p>",[]]',
        numCorrectResponders: 0,
        gabaritos: '[null,[]]',
        meta: { institution: 'PROVA SELETIVA', year: '2022', subject: null },
        examID: 'VOuFj4xjGIe6wZhiJalvKFKxsza2',
        alternatives: '[{"value":"Certo"},{"value":"Errado"}]',
        numResponders: 0,
        pendingImages: true
      },
      {
        images: '[]',
        comments: [],
        flagCE: true,
        dados: null,
        answers: [],
        questionNumber: 8,
        relatedText: null,
        tags: {},
        difficulty: null,
        enunciados: '["<p>A grande pirâmide de Giza, construída para o faraó Khufu, em $2694$ a.C., é uma façanha da arquitetura e engenharia que atrai, há séculos, a imaginação e curiosidade dos mais diversos povos. Ela possui $2.300.000$ blocos, cujos pesos variam entre $2,5$ e $80$ toneladas, e levou $20$ anos para ser construída. Do ponto de vista da matemática, a pirâmide de Khufu é uma obra fascinante, pelo fato de se aproximar de uma pirâmide áurea. A razão áurea desperta bastante curiosidade por ter sido associada a uma estética agradável e por ter relações com outros objetos matemáticos como a sequência de Fibonacci, definida por $f_{n+2} = f_n + f_{n+1}$, para todo $n \\\\geq 1$, com $f_1 = f_2 = 1$. Uma pirâmide reta de base quadrada será áurea se a razão entre apótema de uma face lateral e a metade da aresta da base for a razão áurea $\\\\varphi$, que corresponde à raiz positiva da equação $ r^2 - r - 1 = 0$. A figura seguinte ilustra uma pirâmide reta de base quadrada que possui as mesmas proporções da grande pirâmide de Giza.</p><p>[Imagem]</p><p>Considerando o texto e a imagem precedentes, julgue os itens a seguir.</p><p><strong>57 </strong>Na figura apresentada, os triângulos $\\\\Delta OAB$ e $\\\\Delta ABC$ são semelhantes.</p>",[]]',
        numCorrectResponders: 0,
        gabaritos: '[null,[]]',
        meta: { institution: 'PROVA SELETIVA', year: '2022', subject: null },
        examID: 'VOuFj4xjGIe6wZhiJalvKFKxsza2',
        alternatives: '[{"value":"Certo"},{"value":"Errado"}]',
        numResponders: 0,
        pendingImages: true
      },
      {
        images: '[]',
        comments: [],
        flagCE: true,
        dados: null,
        answers: [],
        questionNumber: 9,
        relatedText: null,
        tags: {},
        difficulty: null,
        enunciados: '["<p>A grande pirâmide de Giza, construída para o faraó Khufu, em $2694$ a.C., é uma façanha da arquitetura e engenharia que atrai, há séculos, a imaginação e curiosidade dos mais diversos povos. Ela possui $2.300.000$ blocos, cujos pesos variam entre $2,5$ e $80$ toneladas, e levou $20$ anos para ser construída. Do ponto de vista da matemática, a pirâmide de Khufu é uma obra fascinante, pelo fato de se aproximar de uma pirâmide áurea. A razão áurea desperta bastante curiosidade por ter sido associada a uma estética agradável e por ter relações com outros objetos matemáticos como a sequência de Fibonacci, definida por $f_{n+2} = f_n + f_{n+1}$, para todo $n \\\\geq 1$, com $f_1 = f_2 = 1$. Uma pirâmide reta de base quadrada será áurea se a razão entre apótema de uma face lateral e a metade da aresta da base for a razão áurea $\\\\varphi$, que corresponde à raiz positiva da equação $ r^2 - r - 1 = 0$. A figura seguinte ilustra uma pirâmide reta de base quadrada que possui as mesmas proporções da grande pirâmide de Giza.</p><p>[Imagem]</p><p>Considerando o texto e a imagem precedentes, julgue os itens a seguir.</p><p><strong>58 </strong>Uma pirâmide reta de base quadrada é uma pirâmide áurea se o  quadrado da altura for igual à área de uma face triangular.</p>",[]]',
        numCorrectResponders: 0,
        gabaritos: '[null,[]]',
        meta: { institution: 'PROVA SELETIVA', year: '2022', subject: null },
        examID: 'VOuFj4xjGIe6wZhiJalvKFKxsza2',
        alternatives: '[{"value":"Certo"},{"value":"Errado"}]',
        numResponders: 0,
        pendingImages: true
      },
      {
        images: '[]',
        comments: [],
        flagCE: true,
        dados: null,
        answers: [],
        questionNumber: 10,
        relatedText: null,
        tags: {},
        difficulty: null,
        enunciados: '["<p>A grande pirâmide de Giza, construída para o faraó Khufu, em $2694$ a.C., é uma façanha da arquitetura e engenharia que atrai, há séculos, a imaginação e curiosidade dos mais diversos povos. Ela possui $2.300.000$ blocos, cujos pesos variam entre $2,5$ e $80$ toneladas, e levou $20$ anos para ser construída. Do ponto de vista da matemática, a pirâmide de Khufu é uma obra fascinante, pelo fato de se aproximar de uma pirâmide áurea. A razão áurea desperta bastante curiosidade por ter sido associada a uma estética agradável e por ter relações com outros objetos matemáticos como a sequência de Fibonacci, definida por $f_{n+2} = f_n + f_{n+1}$, para todo $n \\\\geq 1$, com $f_1 = f_2 = 1$. Uma pirâmide reta de base quadrada será áurea se a razão entre apótema de uma face lateral e a metade da aresta da base for a razão áurea $\\\\varphi$, que corresponde à raiz positiva da equação $ r^2 - r - 1 = 0$. A figura seguinte ilustra uma pirâmide reta de base quadrada que possui as mesmas proporções da grande pirâmide de Giza.</p><p>[Imagem]</p><p>Considerando o texto e a imagem precedentes, julgue os itens a seguir.</p><p><strong>59 </strong>Se $f_n$ é o n-ésimo termo da sequência de Fibonacci, então $\\\\varphi^7 = f_8\\\\varphi + f_7$</p>",[]]',
        numCorrectResponders: 0,
        gabaritos: '[null,[]]',
        meta: { institution: 'PROVA SELETIVA', year: '2022', subject: null },
        examID: 'VOuFj4xjGIe6wZhiJalvKFKxsza2',
        alternatives: '[{"value":"Certo"},{"value":"Errado"}]',
        numResponders: 0,
        pendingImages: true
      },
      {
        images: '[]',
        comments: [],
        flagCE: true,
        dados: null,
        answers: [],
        questionNumber: 11,
        relatedText: null,
        tags: {},
        difficulty: null,
        enunciados: '["<p><strong>A uma saudade</strong></p><p>Em o horror desta muda soledade,</p><p>Onde voando os ares a porfia,</p><p>Apenas solta a luz a aurora fria,</p><p>Quando a prende da noite a escuridade.</p><p>Ah cruel apreensão de uma saudade!</p><p>De uma falsa esperança fantasia,</p><p>Que faz que de um momento passe a um dia,</p><p>E que de um dia passe à eternidade!</p><p>São da dor os espaços sem medida,</p><p>E a medida das horas tão pequena,</p><p>Que não sei como a dor é tão crescida.</p><p>Mas é troca cruel, que o fado ordena,</p><p>Porque a pena me cresça para a vida,</p><p>Quando a vida me falta para a pena.</p><p></p><p>A partir do poema <strong>A uma saudade</strong>, de Gregório de Matos, julgue os itens a seguir.</p><p><strong>39 </strong>O poema, que exemplifica a lírica filosófica de Gregório de Matos, expressa a reflexão de que o indivíduo é mínimo diante da grandiosidade de um universo que o expande.</p>",[]]',
        numCorrectResponders: 0,
        gabaritos: '[null,[]]',
        meta: { institution: 'PROVA SELETIVA', year: '2022', subject: null },
        examID: 'VOuFj4xjGIe6wZhiJalvKFKxsza2',
        alternatives: '[{"value":"Certo"},{"value":"Errado"}]',
        numResponders: 0,
        pendingImages: false
      },
      {
        images: '[]',
        comments: [],
        flagCE: true,
        dados: null,
        answers: [],
        questionNumber: 12,
        relatedText: null,
        tags: {},
        difficulty: null,
        enunciados: '["<p><strong>A uma saudade</strong></p><p>Em o horror desta muda soledade,</p><p>Onde voando os ares a porfia,</p><p>Apenas solta a luz a aurora fria,</p><p>Quando a prende da noite a escuridade.</p><p>Ah cruel apreensão de uma saudade!</p><p>De uma falsa esperança fantasia,</p><p>Que faz que de um momento passe a um dia,</p><p>E que de um dia passe à eternidade!</p><p>São da dor os espaços sem medida,</p><p>E a medida das horas tão pequena,</p><p>Que não sei como a dor é tão crescida.</p><p>Mas é troca cruel, que o fado ordena,</p><p>Porque a pena me cresça para a vida,</p><p>Quando a vida me falta para a pena.</p><p></p><p>A partir do poema <strong>A uma saudade</strong>, de Gregório de Matos, julgue os itens a seguir.</p><p><strong>39 </strong>No soneto, o eu lírico se vale de reflexões acerca da natureza e da condição humana para expressar sua critica mordaz acerca da sociedade setecentista.</p>",[]]',
        numCorrectResponders: 0,
        gabaritos: '[null,[]]',
        meta: { institution: 'PROVA SELETIVA', year: '2022', subject: null },
        examID: 'VOuFj4xjGIe6wZhiJalvKFKxsza2',
        alternatives: '[{"value":"Certo"},{"value":"Errado"}]',
        numResponders: 0,
        pendingImages: false
      },
      {
        images: '[]',
        comments: [],
        flagCE: true,
        dados: null,
        answers: [],
        questionNumber: 13,
        relatedText: null,
        tags: {},
        difficulty: null,
        enunciados: '["<p><strong>A uma saudade</strong></p><p>Em o horror desta muda soledade,</p><p>Onde voando os ares a porfia,</p><p>Apenas solta a luz a aurora fria,</p><p>Quando a prende da noite a escuridade.</p><p>Ah cruel apreensão de uma saudade!</p><p>De uma falsa esperança fantasia,</p><p>Que faz que de um momento passe a um dia,</p><p>E que de um dia passe à eternidade!</p><p>São da dor os espaços sem medida,</p><p>E a medida das horas tão pequena,</p><p>Que não sei como a dor é tão crescida.</p><p>Mas é troca cruel, que o fado ordena,</p><p>Porque a pena me cresça para a vida,</p><p>Quando a vida me falta para a pena.</p><p></p><p>A partir do poema <strong>A uma saudade</strong>, de Gregório de Matos, julgue os itens a seguir.</p><p><strong>39 </strong>Embora conhecido pela alcunha de Boca do Inferno, Gregório de Matos, em sua poesia religiosa, utilizou-se de recursos da própria Bíblia para se dedicar ao sentimento religioso.</p>",[]]',
        numCorrectResponders: 0,
        gabaritos: '[null,[]]',
        meta: { institution: 'PROVA SELETIVA', year: '2022', subject: null },
        examID: 'VOuFj4xjGIe6wZhiJalvKFKxsza2',
        alternatives: '[{"value":"Certo"},{"value":"Errado"}]',
        numResponders: 0,
        pendingImages: false
      },
    ],
  } },
  mounted() { this.reRender(); },
  methods: {
    reRender() { if ('MathJax' in window) { this.$nextTick(function() { window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub, document.body]) }) } },
    parsedEnunciados(q) { return JSON.parse(q.enunciados) },
    parsedGabaritos(q)  { return JSON.parse(q.gabaritos) },
    parsedAlternativas(q) { return JSON.parse(q.alternatives) },
    toggleInspectionMode() {
      window.MathJax.Hub.Queue(this.inspectionMode ? ["Reprocess", window.MathJax.Hub] : ()=>{
        const HTML = window.MathJax.HTML, jax = window.MathJax.Hub.getAllJax();
        for (let i = 0, m = jax.length; i < m; i++) {
          let script = jax[i].SourceElement(), tex = jax[i].originalText;
          if (script.type.match(/display/)) {tex = "$$"+tex+"$$"}
          else {tex = "$"+tex+"$"}
          jax[i].Remove();
          let preview = script.previousSibling;
          if (preview && preview.className === "MathJax_Preview") {
            preview.parentNode.removeChild(preview);
          }
          preview = HTML.Element("span",{className:"MathJax_Preview"},[tex]);
          script.parentNode.insertBefore(preview,script);
        }
      }); this.inspectionMode = !this.inspectionMode;
    }
  },
}
</script>

<style scoped>
.desktop-dados-alignment { margin-left: 0; }
.desktop-container-padding { padding: 20px 40px; }
.desktop-alternatives-padding { padding-left: 30px; }
</style>
